import {Injectable, Injector} from '@angular/core';
import {map, tap} from 'rxjs';
import {RadJobAiDto} from 'src/app/interfaces/backend/dto/rad-job-ai-dto.interface';
import {RadiologyAiControllerService} from 'src/app/services/backend/radiology-ai-controller.service';
import {displaySuccess} from 'src/app/utilities/toast.util';

@Injectable({providedIn: 'root'})
export class RadiologyTaskAIResultsFacade {
    public data: RadJobAiDto[] = [];
    public activeItem: RadJobAiDto | null = null;

    constructor(
        private radiologyAiControllerService: RadiologyAiControllerService,
        private injector: Injector
    ) {
    }

    public reset() {
        this.data = [];
        this.activeItem = null;
    }

    public loadData(radiologyTaskId: number) {
        this.loadDataLogic(radiologyTaskId).subscribe();
    }

    public loadDataLogic(radiologyTaskId: number) {
        return this.getData(radiologyTaskId).pipe(
            tap(data => this.data = data)
        );
    }

    public getData(radiologyTaskId: number) {
        return this.radiologyAiControllerService.getRadiologyTaskAiResults(radiologyTaskId).pipe(
            map((d) => {
                return d.map(entry => {
                    const result: RadJobAiDto = {
                        ...entry,
                        data: entry.data ? JSON.parse(entry.data as string) : undefined
                    };

                    return result;
                });
            })
        );
    }

    public orderAiAlgorithm(radJobAiDto: RadJobAiDto, callback: () => void) {
        this.radiologyAiControllerService.orderAiAlgorithm(radJobAiDto).subscribe(() => {
            displaySuccess(this.injector);
            callback();
        });
    }
}